import { default as Dayjs, default as dayjs } from 'dayjs';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { FirebaseAuthContext } from '../SignIn';
import { StatusBadge } from '../Tradelog/Tradelog';
import { TradelogContext } from '../Tradelog/context';
import { callApi } from '../util';
// Get the current time in Unix format
const now = dayjs().unix();

export function ForexPair({ pair }) {
    return (
        <span>
            <span className={`fx fx-${pair.slice(0, 3)}`}>{pair.slice(0, 3)}</span>
            <span className={`fx fx-${pair.slice(3)}`}>{pair.slice(3)}</span>
        </span>
    );
}

const TableHeaders = ({ isMobile }) => (
    <thead>
        <tr className="bordered">
            {!isMobile && <th>Rank</th>}
            <th>Epic</th>
            <th>Score</th>
            {!isMobile && <th>Signals</th>}
            {!isMobile && <th>Trade</th>}
            <th>Timestamp</th>
        </tr>
    </thead>
);

const TableRow = ({ asset, idx, isMobile, navigate }) => (
    <React.Fragment key={idx}>
        <tr className={`padded-top ${(isMobile ? "" : "bordered")}`}>
            {!isMobile && <td><b>{asset.rank}</b></td>}
            <td>
                <a href={`https://www.tradingview.com/chart/YiD4OsYt/?symbol=${asset.symbol}&interval=720`} target="_blank" className="no-link" rel="noreferrer">
                    {asset.type === "forex" ? <span>{asset.symbol.split(":")[0]}:<ForexPair pair={asset.epic} /></span> : asset.symbol}
                </a>
            </td>
            <td>
                {asset.index_score}%
            </td>
            {!isMobile && (
                <>
                    <td>
                        <div>{signals("360", asset)}</div>
                        <div className="opacity-70">{signals("200", asset)}</div>
                        <div className="opacity-40">{signals("100", asset)}</div>
                        <div className="opacity-40">{signals("50", asset)}</div>
                    </td>
                    <td>
                        {asset.trades.map(t => (
                            <a href="#" key={t.trade_id} className={`me-1 ${t.className}`} onClick={() => navigate(`/log/trade/${t.trade_id}`)}>
                                <StatusBadge status={t.status} date={t.date} close_date={t.close_date} />
                            </a>
                        ))}
                        {asset.correlatedTrades.length > 0 && asset.type === "forex" && <div className="pt-3 border-top mt-3">
                            {asset.correlatedTrades.map(t => (<div key={t.trade_id} className="pb-2">
                                <a href={`https://www.tradingview.com/chart/YiD4OsYt/?symbol=${asset.symbol}&interval=720`} target="_blank" className="no-link" rel="noreferrer">
                                    <ForexPair pair={t.pair} />
                                </a> <a href="#" className={`me-1 ${t.className}`} onClick={() => navigate(`/log/trade/${t.trade_id}`)}><StatusBadge status={t.status} date={t.date} close_date={t.close_date} /></a>
                            </div>))}
                        </div>}
                    </td>
                </>
            )}
            <td>{asset.timestamp}</td>
        </tr>
        {isMobile && (
            <tr className="bordered">
                <td >
                    <div>{signals("360", asset)}</div>
                    <div className="opacity-70">{signals("200", asset)}</div>
                    <div className="opacity-40">{signals("100", asset)}</div>
                    <div className="opacity-40">{signals("50", asset)}</div>
                </td>
                <td >
                    {asset.trades.map(t => (
                        <a href="#" key={t.trade_id} className={`me-1 ${t.className}`} onClick={() => navigate(`/log/trade/${t.trade_id}`)}>
                            <StatusBadge status={t.status} date={t.date} close_date={t.close_date} />
                        </a>
                    ))}
                    {asset.correlatedTrades.length > 0  && asset.type === "forex" && <div className="pt-3 border-top mt-3">
                        {asset.correlatedTrades.map(t => (<div key={t.trade_id} className="pb-2">
                            <a href={`https://www.tradingview.com/chart/YiD4OsYt/?symbol=${asset.symbol}&interval=720`} target="_blank" className="no-link" rel="noreferrer">
                                <ForexPair pair={t.pair} />
                            </a> <a href="#" className={`me-1 ${t.className}`} onClick={() => navigate(`/log/trade/${t.trade_id}`)}><StatusBadge status={t.status} date={t.date} close_date={t.close_date} /></a>
                        </div>))}
                    </div>}
                </td>
            </tr>
        )}
    </React.Fragment>
);

const signals = (type, asset) => (
    <div className="d-flex align-items-center">
        <span style={{ width: "3rem" }}>{type}</span>
        <span style={{ width: "4rem" }} className={Math.abs(asset[`z_score_${type}`]) < 2 ? "opacity-70" : Math.abs(asset[`z_score_${type}`]) > 3 ? "text-warning fw-bold" : ""}>
            {asset[`z_score_${type}`].toFixed(2)}
        </span>
        <span>{asset.recent_closes.map((i, index) => asset["overbought_" + type][index] ? '🔴' : (asset["oversold_" + type][index] ? '🟢' : '⚪'))}</span>
    </div>
);

export default function Screener() {
    const user = useContext(FirebaseAuthContext); // Access the token
    const api = useContext(TradelogContext);
    const [minZScore, setMinZScore] = useState(2.4);
    const [screenerData, setScreener] = useState([]);
    const navigate = useNavigate();
    const [copySuccess, setCopySuccess] = useState({ color: "primary", text: 'Copy Watchlist' });

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            setCopySuccess({ color: "success", text: 'Copied!' });
            await callApi(user, "POST", `/capital_com/refresh-watchlist`);
            // * open tradingview in new tab
            // window.open(`https://www.tradingview.com/chart/YiD4OsYt/?interval=720`, '_blank');
        } catch (err) {
            setCopySuccess({ color: "danger", text: 'Failed to copy!' });
        }
    };

    useEffect(() => {
        setCopySuccess({ color: "primary", text: 'Copy Watchlist' });
    }, [minZScore]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await callApi(user, "GET", `/capital_com/screener`);
                setScreener(data);
                setCopySuccess({ color: "primary", text: 'Copy Watchlist' });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 5 * 60 * 1000); // Refresh every 5 minutes

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [user]);

    const trades = useMemo(() => api.allTrades.sort((a, b) => b.open_unix - a.open_unix).map(i => ({
        ...i,
        isOlderThan3Weeks: now - i.open_unix > 3 * 7 * 24 * 60 * 60,
        isOlderThan6Weeks: now - i.open_unix > 6 * 7 * 24 * 60 * 60,
        isOlderThen12Weeks: now - i.open_unix > 12 * 7 * 24 * 60 * 60,
        date: dayjs.unix(i.open_unix).format("D MMM"),
        close_date: i.close_unix ? dayjs.unix(i.close_unix).format("D MMM") : null,
    })).map(i => ({ ...i, className: i.isOlderThan6Weeks ? "opacity-40" : (i.isOlderThan3Weeks ? "opacity-70" : "") })), [api.allTrades]);

    const data = useMemo(() => screenerData.sort((a, b) => b.index_score - a.index_score).map(i => ({
        ...i,
        trades: trades.filter(j => j.pair === i.epic).slice(0, 4),
        correlatedTrades: trades.filter(j =>
            j.type === "forex" &&
            ["waiting", "unfilled", "filled"].includes(j.status) &&
            j.pair !== i.epic && (
                [i.epic.slice(0, 3), i.epic.slice(-3)].includes(j.pair.slice(0, 3)) ||
                [i.epic.slice(0, 3), i.epic.slice(-3)].includes(j.pair.slice(-3))
            )),
        overbought_50: i.recent_highs.map(price => price > (i.sma_50 + (i.std_dev_50 * (minZScore + 0.2)))),
        oversold_50: i.recent_lows.map(price => price < (i.sma_50 - (i.std_dev_50 * (minZScore + 0.2)))),
        overbought_100: i.recent_highs.map(price => price > (i.sma_100 + (i.std_dev_100 * minZScore))),
        oversold_100: i.recent_lows.map(price => price < (i.sma_100 - (i.std_dev_100 * minZScore))),
        overbought_360: i.recent_highs.map(price => price > (i.sma_360 + (i.std_dev_360 * minZScore))),
        oversold_360: i.recent_lows.map(price => price < (i.sma_360 - (i.std_dev_360 * minZScore))),
        overbought_200: i.recent_highs.map(price => price > (i.sma_200 + (i.std_dev_200 * minZScore))),
        oversold_200: i.recent_lows.map(price => price < (i.sma_200 - (i.std_dev_200 * minZScore))),
    })).filter(i => i.overbought_360.includes(true) || i.oversold_360.includes(true) || i.overbought_200.includes(true) || i.oversold_200.includes(true) || i.overbought_100.includes(true) || i.oversold_100.includes(true) || i.overbought_50.includes(true) || i.oversold_50.includes(true)),
    [screenerData, trades, minZScore]);

    const semiOldTrades = trades.filter(i => !i.isOlderThan12Weeks);
    const watchlistString = [
        "###Open Trades",
        ...trades.filter(i => i.status === "filled").map(trade => `${trade.exchange}:${trade.pair}`),
        "###Waiting for setup",
        ...trades.filter(i => i.status === "waiting" || i.status === "unfilled").map(trade => `${trade.exchange}:${trade.pair}`),
        "###Signals " + Dayjs().format('ddd D MMM - HH:mm'),
        ...data.map(i => i.symbol),
        "###Profitable Trades",
        ...semiOldTrades.filter(i => i.result > 2).map(trade => `${trade.exchange}:${trade.pair}`),
        "###Losing Trades",
        ...semiOldTrades.filter(i => i.result < -2).map(trade => `${trade.exchange}:${trade.pair}`),
        "###Break-Even Trades",
        ...semiOldTrades.filter(i => i.result >= -2 && i.result <= 2).map(trade => `${trade.exchange}:${trade.pair}`),
        "###Cancelled Trades",
        ...semiOldTrades.filter(i => i.status === "cancelled").map(trade => `${trade.exchange}:${trade.pair}`),
    ].join(",");

    return (
        <div>
            <Container className="py-5 px-3">
                <Row className="align-items-center">
                    <Col md={6}>
                        <h3 className="text-white mb-0">Screener
                            <Button color={copySuccess.color} className="ms-2" onClick={() => copyToClipboard(watchlistString)}>{copySuccess.text}</Button>

                        </h3>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="zscorerange" className="text-white">
                                Min Z-score <b><span className="text-info">{Number(minZScore).toFixed(1)}</span></b>
                            </Label>
                            <Input
                                id="zscorerange"
                                name="range"
                                type="range"
                                value={minZScore}
                                min={0}
                                max={3.1}
                                step={0.1}
                                onChange={(e) => setMinZScore(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                {/* Desktop view for Forex */}
                <div className="d-none d-md-block py-3">
                    <Table responsive borderless>
                        <TableHeaders isMobile={false} />
                        <tbody>
                            {data.map((asset, idx) => (
                                <TableRow asset={asset} idx={idx} isMobile={false} navigate={navigate} />
                            ))}
                        </tbody>
                    </Table>
                </div>

                {/* Mobile view for Forex */}
                <div className="d-md-none py-3">
                    <Table responsive borderless>
                        <TableHeaders isMobile={true} />
                        <tbody>
                            {data.map((asset, idx) => (
                                <TableRow asset={asset} idx={idx} isMobile={true} navigate={navigate} />
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Container>
        </div>
    );
}
